<template>
  <div class="datalytics-container">
    <div class="table-section">
      <h1>Datalytics</h1>
      <UploadButton @file-selected="handleFile" />

      <TableView :tableData="fileData" />
    </div>

    <div class="chart-section">
      <h2>Visualization</h2>

      <label for="chartType">Chart type</label>
      <select id="chartType" v-model="selectedChartType" @change="updateChartType">
        <option value="bar">Bar</option>
        <option value="line">Line</option>
        <option value="area">Area</option>
        <option value="pie">Pie</option>
        <option value="donut">Donut</option>
      </select>

      <apexchart 
        v-if="selectedChartType && chartData.length"
        :key="selectedChartType"
        :type="selectedChartType" 
        :options="chartOptions" 
        :series="chartData" />
    </div>
  </div>
</template>

<script>
import UploadButton from '@/components/Datalytics/UploadButton.vue';
import TableView from '@/components/Datalytics/TableView.vue';
import VueApexCharts from 'vue3-apexcharts';

import * as XLSX from "xlsx";
import Papa from "papaparse";

export default {
  name: 'DatalyticsApp',
  components: {
    UploadButton,
    TableView,
    apexchart: VueApexCharts
  },
  data() {
    return {
      fileData: [],
      chartData: [],
      selectedChartType: "",
      chartOptions: {
        chart: {
          id: 'dynamic-chart'
        }
      }
    };
  },
  methods: {
    handleFile(file) {
      const fileType = file.name.split('.').pop();
      const reader = new FileReader();

      if (fileType === 'csv') {
        reader.onload = (event) => {
          const csvData = event.target.result;

          Papa.parse(csvData, {
            complete: (result) => {
              this.fileData = result.data;
              this.prepareChartData();
            },
            header: false
          });
        };

        reader.readAsText(file);
      } else if (fileType === 'xlsx') {
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          
          this.fileData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          this.prepareChartData();
        };

        reader.readAsArrayBuffer(file);
      } else {
        alert("Unsupported file format. Please, upload a .csv or .xlsx file.")
      }
    },
    prepareChartData() {
      if (this.fileData.length > 1) {
        const headers = this.fileData[0];
        const rows = this.fileData.slice(1);

        if (this.selectedChartType === 'pie' || this.selectedChartType === 'donut') {
          console.log(this.selectedChartType)

          this.chartOptions = {
            ...this.chartOptions, 
            labels: headers.slice(1)
          };

          this.chartData = [
            rows.reduce((acc, row) => {
              return acc.map((value, index) => value + (parseFloat(row[index + 1]) || 0));
            }, Array(headers.length - 1).fill(0))
          ];

          console.log(this.chartData);
          console.log(this.chartOptions);
        } else {
          console.log('2', this.selectedChartType)

          this.chartOptions = { 
            ...this.chartOptions,
            xaxis: {
              categories: headers.slice(1)
            }
          };
          
          this.chartData = headers.slice(1).map((header, index) => {
            return {
              name: header,
              data: rows.map(row => parseFloat(row[index + 1]) || 0)
            };
          });

          console.log(this.chartData);
          console.log(this.chartOptions);
        }
      }
    }
  }
};
</script>

<style scoped>
.datalytics-container {
  display: flex;
  justify-content: space-between;
}

.table-section {
  width: 50%;
}

.chart-section {
  width: 50%;
}
</style>