<template>
  <div>
    <input type="file" @change="handleFileUpload" />
  </div>
</template>

<script>
export default {
  methods: {
    handleFileUpload(e) {
      const file = e.target.files[0];
      this.$emit('file-selected', file);
    }
  }
};
</script>

<style scoped></style>