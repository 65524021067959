<template>
  <div v-if="tableData.length">
    <h2>Spreadsheet content:</h2>

    <table>
      <thead>
        <tr>
          <th v-for="(header, index) in tableData[0]" :key="index">{{ header }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(row, rowIndex) in tableData.slice(1)" :key="rowIndex">
          <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #DDDDDD;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #F2F2F2;
}
</style>